<template lang="pug">
div.fake-login-box.text-center
  //- img.fake-login-img(:src="dodoGif")
</template>

<script>
import lioshutanApi from '@/api';
import { alertFailedMessage } from '@/utils/sweetAlert.js';
import dodoGif from '@/assets/gif/dodo.gif';
import { setUseLangByRole } from '@/utils/lang';
import WuwowLoadingMixin from '@/components/wuwowLoading/mixin/index.vue';

export default {
  name: 'FakeLogin',

  mixins: [WuwowLoadingMixin],

  data() {
    return {
      dodoGif: dodoGif,
    };
  },

  async created() {
    this.$store.dispatch('auth/setToken', localStorage.getItem('wuwow_token'));
    this.$store.dispatch('auth/setRole', localStorage.getItem('account_role'));
    await this.fakeLogin();
  },

  methods: {
    async fakeLogin() {
      // 開啟 wuowow Loading
      const tag = this.startLoading();

      try {
        const fakeToken = this.$route.params.token;
        this.$store.dispatch('parents/removeVipId');
        this.$store.dispatch('auth/setToken', fakeToken);
        const accountRole = await this.setAccountAuthorityToLocalStorage();
        setUseLangByRole(accountRole);

        // 把 line@ 訂課服務網址帶來的 user_id(學生的)，存到 localStorage裡
        if (this.$route.query.user_id) {
          this.$store.dispatch('parents/setVipId', this.$route.query.user_id);
        }
        if (accountRole === 'teacher') {
          this.$router.push({ path: '/dojo' });
          this.endLoading(tag);
          return;
        }

        // 關閉 wuowow Loading
        this.endLoading(tag);
        // 根據 line@ 訂課服務網址帶來的 query string轉址
        switch (this.$route.query.action) {
          // 依日期訂課
          case 'book_class':
            this.$router.push({ path: '/dojo/book_class' });
            break;
          // 依顧問訂課
          case 'consultants':
            this.$router.push({ path: '/dojo/consultants' });
            break;

          // 規律訂課
          case 'schedule/fixed':
            // this.$router.push({ path: '/dojo/schedule/fixed' });
            this.$router.push({ name: 'vip-fixed-schedule' });
            break;

          // 開始上課
          case 'classRoom':
            this.$router.push({ name: 'class-room' });
            break;

          // 推薦好友
          case 'vip_mgm':
            this.$router.push({ name: 'vip-mgm' });
            break;

          // 訂課紀錄
          case 'book_class_record':
            this.$router.push({ path: '/dojo/bookClassRecord' });
            break;

          // 個人資料
          case 'account/profile':
            this.$router.push({ path: '/dojo/account/profile' });
            break;

          default:
            this.directToNewJr(); // 家長登入到新後台
            // this.$router.push({ path: '/dojo' });
            break;
        }
      } catch (error) {
        alertFailedMessage('壞壞失敗!');
      }
    },
    directToNewJr() {
      const url = new URL(window.location);
      const a = document.createElement('a');
      a.setAttribute('href', `${url.origin}/student/`);
      a.click();
    },
    async setAccountAuthorityToLocalStorage() {
      const accountAuthority = await lioshutanApi.vip.getAccountAuthority();
      const accountRole = accountAuthority.data.data.role;
      this.$store.dispatch('auth/setRole', accountRole);
      return accountRole;
    },
  },
};
</script>

<style lang="scss" scoped>
.fake-login-box {
  height: 75vh;
}

.fake-login-img {
  margin-top: 150px;
}
</style>
